///
/// Messy Play by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Arrow */

	a.arrow {
		border-bottom: 0;
		display: inline-block;
		height: 4rem;
		position: relative;
		width: 6rem;

		.label {
			display: none;
		}

		&:before {
			background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512"><path d="M442.53,346.28L293.65,495.16c-0.33,0.33-0.7,0.62-1.05,0.94c-9.16,9.77-22.15,15.9-36.6,15.9 c-14.44,0-27.43-6.13-36.59-15.88c-0.35-0.33-0.72-0.62-1.06-0.96L69.47,346.28c-19.62-19.62-19.62-51.44,0-71.05 c19.62-19.62,51.43-19.62,71.06,0l65.23,65.23V50.24C205.76,22.5,228.25,0,256,0s50.24,22.5,50.24,50.24v290.21l65.23-65.23 c19.62-19.62,51.43-19.62,71.05,0C462.15,294.84,462.15,326.66,442.53,346.28z" fill="#{_palette(fg-bold)}" /></svg>');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			content: '';
			display: inline-block;
			height: 100%;
			position: relative;
			width: 3rem;
		}
	}