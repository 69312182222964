// Misc.
	$misc: (
		z-index-base:				10000,
		gallery-lightbox-opacity:	0.875
	);

// Duration.
	$duration: (
		transition:					0.25s,
		gallery-lightbox-fadein:	0.5s
	);

// Size.
	$size: (
		border-radius:			0.325rem,
		element-height:			3rem,
		element-margin:			2rem,
		header-width:			25rem,
		subheader-width:		22rem,
		content-width:			60rem,
		line-width:				2px,
		form-gutter:			2rem,
		gallery-gutter:			1.25rem,
	);

// Font.
	$font: (
		family:					('Source Sans Pro', Helvetica, sans-serif),
		family-fixed:			('Courier New', monospace),
		family-heading:			('Raleway', Helvetica, sans-serif),
		weight:					300,
		weight-bold:			600,
		weight-extrabold:		700,
		weight-heading:			800,
		weight-heading-light:	600,
		kerning:				0.0375em,
		kerning-heading:		0.175em,
		kerning-heading-alt:	-0.05em
	);

// Palette.
	$palette: (
		bg:						#ffffff,
		fg:						#000000,
		fg-bold:				#000000,
		fg-light:				rgba(0,0,0,0.25),
		border:					rgba(144,144,144,0.25),
		border-bg:				rgba(144,144,144,0.1),
		accent:					#70dfb7,
		accent-border:			desaturate(darken(#70dfb7, 8), 30),
		accent-alt:				desaturate(darken(#70dfb7, 10), 20),
		accent-bg:				transparentize(#70dfb7, 0.75)
	);