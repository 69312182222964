///
/// Messy Play by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Gallery */

	@include keyframes('gallery-modal-spinner') {
		0% {
			@include vendor('transform', 'rotate(0deg)');
		}

		100% {
			@include vendor('transform', 'rotate(360deg)');
		}
	}

	.gallery {
		$gutter: _size(gallery-gutter);

		display: flex;
		flex-wrap: wrap;
		margin: ($gutter * -1) 0 0 ($gutter * -1);
		width: calc(100% + #{$gutter});

		a {
			border-bottom: 0;
			display: block;
			margin: $gutter 0 0 $gutter;
			outline: 0;
			position: relative;
			width: calc(50% - #{$gutter});

			img {
				display: block;
				height: 25vw;
				min-height: 18rem;
				object-fit: cover;
				object-position: center;
				width: 100%;
			}

			&.landscape {
				width: 100%;

				img {
					height: 30vw;
				}
			}

			&.portrait {
				img {
					height: 30vw;
				}
			}
		}

		.modal {
			@include vendor('display', 'flex');
			@include vendor('align-items', 'center');
			@include vendor('justify-content', 'center');
			@include vendor('pointer-events', 'none');
			@include vendor('user-select', 'none');
			@include vendor('transition', (
				'opacity #{_duration(gallery-lightbox-fadein)} ease',
				'visibility #{_duration(gallery-lightbox-fadein)}',
				'z-index #{_duration(gallery-lightbox-fadein)}'
			));
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			background-color: transparentize(_palette(bg), 1 - _misc(gallery-lightbox-opacity));
			height: 100%;
			left: 0;
			opacity: 0;
			outline: 0;
			position: fixed;
			top: 0;
			visibility: none;
			width: 100%;
			z-index: 0;

			&:before {
				@include vendor('animation', 'gallery-modal-spinner 1s infinite linear');
				@include vendor('transition', 'opacity #{_duration(gallery-lightbox-fadein) * 0.5} ease');
				@include vendor('transition-delay', '#{_duration(gallery-lightbox-fadein)}');
				background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="96px" height="96px" viewBox="0 0 96 96" zoomAndPan="disable"><style>circle {fill: transparent; stroke: #{_palette(fg-bold)}; stroke-width: 2px; }</style><defs><clipPath id="corner"><polygon points="0,0 48,0 48,48 96,48 96,96 0,96" /></clipPath></defs><g clip-path="url(#corner)"><circle cx="48" cy="48" r="32"/></g></svg>');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 4rem;
				content: '';
				display: block;
				height: 4rem;
				left: 50%;
				margin: -2rem 0 0 -2rem;
				opacity: 0;
				position: absolute;
				top: 50%;
				width: 4rem;
			}

			&:after {
				background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 64 64" zoomAndPan="disable"><style>line {stroke: #{_palette(fg-bold)};stroke-width: 2px;}</style><line x1="20" y1="20" x2="44" y2="44" /><line x1="20" y1="44" x2="44" y2="20" /></svg>');
				background-position: center;
				background-repeat: no-repeat;
				background-size: 3rem;
				content: '';
				cursor: pointer;
				display: block;
				height: 4rem;
				position: absolute;
				right: 0.5rem;
				top: 0.5rem;
				width: 4rem;
			}

			.inner {
				@include vendor('transform', 'translateY(0.75rem)');
				@include vendor('transition', (
					'opacity #{_duration(gallery-lightbox-fadein) * 0.5} ease',
					'transform #{_duration(gallery-lightbox-fadein) * 0.5} ease'
				));
				opacity: 0;

				img {
					box-shadow: 0 1rem 3rem 0 rgba(0, 0, 0, 0.35);
					display: block;
					max-height: 90vh;
					max-width: 90vw;
				}
			}

			&.visible {
				@include vendor('pointer-events', 'auto');
				opacity: 1;
				visibility: visible;
				z-index: _misc(z-index-base) + 1000;

				&:before {
					opacity: 1;
				}
			}

			&.loaded {
				.inner {
					@include vendor('transform', 'translateY(0)');
					@include vendor('transition', (
						'opacity #{_duration(gallery-lightbox-fadein)} ease',
						'transform #{_duration(gallery-lightbox-fadein)} ease'
					));
					opacity: 1;
				}

				&:before {
					@include vendor('transition-delay', '0s');
					opacity: 0;
				}
			}
		}

		@include breakpoint('<=medium') {
			a {
				img {
					height: 20rem;
				}

				&.landscape {
					img {
						height: 25rem;
					}
				}

				&.portrait {
					img {
						height: 25rem;
					}
				}
			}

			.modal {
				.inner {
					img {
						max-width: 100vw;
					}
				}
			}
		}

		@include breakpoint('<=small') {
			$gutter: (_size(gallery-gutter) * 0.5);

			margin: ($gutter * -1) 0 0 ($gutter * -1);
			width: calc(100% + #{$gutter});

			a {
				margin: $gutter 0 0 $gutter;
				width: calc(50% - #{$gutter});

				img {
					height: 20rem;
				}

				&.landscape {
					img {
						height: 20rem;
					}
				}

				&.portrait {
					img {
						height: 30rem;
					}
				}
			}
		}

		@include breakpoint('<=xsmall') {
			a {
				img {
					height: 12rem;
					min-height: 0;
				}

				&.landscape {
					img {
						height: 12rem;
					}
				}

				&.portrait {
					img {
						height: 14rem;
					}
				}
			}
		}
	}
